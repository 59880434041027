import ReportDataService from '../services/ReportDataService'
    const state = {
        report: [],
        permanentReport: [],
        onlyPermanetReport: [],
        temporaryPermanentReport: []
    }
    const getters = {
    //   getPositions(state){
    //     return state.designation
    //   }
    }
    const mutations = {
     
      SET_REPORT(state, result){
        state.report = result
      },
      SET_PERMANENT_REPORT(state, result){
        state.permanentReport = result
      },
      SET_ONLY_PERMANENT_REPORT(state, result){
        state.onlyPermanetReport = result
      },
      SET_TEMPORARY_PERMANENT_REPORT(state, result){
        state.temporaryPermanentReport = result
      }
    }
    const actions = {
      async getReport({commit}, payload) {
        await ReportDataService.getAllReport(payload)
        .then(function(res){
          console.log('testsssssssssssssssssssssssss=>'+res.data);
          commit('SET_REPORT',res.data)
        })
      },
      async getPermanentReport({commit}, payload) {
        await ReportDataService.getAllPermanentReport(payload)
        .then(function(res){
          console.log(res.data);
          commit('SET_PERMANENT_REPORT',res.data)
        })
      },
      async getOnlyAllPermanentReport({commit}, payload) {
        await ReportDataService.getOnlyAllPermanentReport(payload)
        .then(function(res){
          console.log(res.data);
          commit('SET_ONLY_PERMANENT_REPORT',res.data)
        })
      },
      async getPermanentTemporaryReport({commit}, payload) {
        await ReportDataService.getPermanentTemporaryReport(payload)
        .then(function(res){
          console.log(res.data);
          commit('SET_TEMPORARY_PERMANENT_REPORT',res.data)
        })
      },
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}