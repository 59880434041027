import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8088/api",
  baseURL: "https://payrollapi.codebett.com/api",
  // baseURL: "https://payrollapi.takesaas.com/api",
  // baseURL: "http://192.168.0.121:8088/api",
  headers: {
    "Content-type": "application/json"
  }
});