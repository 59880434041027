<template>
  <v-app id="inspire">
    <div v-if="$route.name !='login'">
      <v-navigation-drawer
        v-model="drawer"
        dark
        app
      >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Payroll Managment
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon x-large color="green">mdi-circle-small</v-icon> Online {{username}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list rounded>
      <!-- <v-subheader>REPORTS</v-subheader> -->
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to='item.to'
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="primary">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title >{{$route.name}}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="logout">
            <v-icon>  mdi-logout </v-icon>
        </v-btn>
      </v-app-bar>
    </div>
   

   <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import OneSignalVue from 'onesignal-vue';
  export default {
    data () {
      return {
        drawer: null,
        username: '',
        userType: '',
        selectedItem: 1,
        items: [
          { text: 'Dashboard', icon: 'mdi-monitor-dashboard', to:'/dashboard' },
          { text: 'Human Resource', icon: 'mdi-account-multiple', to:'/employee' },
          // { text: 'Department', icon: 'mdi-google-classroom', to:'/department' },
          // { text: 'Payroll', icon: 'mdi-cash-multiple', to:'/payroll' },
          { text: 'Payroll', icon: 'mdi-cash-multiple', to:'/payslip' },
          { text: 'General Report', icon: 'mdi-file-document-multiple', to:'/report' },
          // { text: 'Payroll History', icon: 'mdi-history', to:'/payrollHistory' },
          // { text: 'Payroll Comaprision', icon: 'mdi-cash-multiple', to:'/payrollComparision' },
         
          { text: 'Users', icon: 'mdi-account-circle', to:'/users' },
          { text: 'Configuration', icon: 'mdi-cog-outline', to:'/setting' },
        ],
      }
    },
    methods:{
       logout(){
        // this.$store.dispatch('setAuthentication',false)
        if (confirm("Are you sure to logout?") == true) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('role');
          this.$router.push('/');
        }
        
      },
      getUserInfo(){
        this.username = localStorage.getItem('username')
        this.userType = localStorage.getItem('role')
      }
    },
    created(){
      this.getUserInfo()
    },
    beforeCreate() {
      console.log(this.$OneSignal.showSlidedownPrompt());
      // alert(this.$OneSignal.showSlidedownPrompt()) 
    }
  }
</script>