import PayrollDataService from '../services/PayrollDataService'
import { sumArrayObject } from '../helper/sumArrayObjectProperty'
    const state = {
        // headers: [
        //   { text: 'Full Name',align: 'start',value: 'name'},
        //   { text: 'Phone No', value: 'phone' },
        //   { text: 'Work Type', value: 'workType' },
        //   { text: 'Month', value: 'month' },
        //   { text: 'Year', value: 'year' },
        //   { text: 'Department', value: 'department' },
        //   { text: 'Basic Salary', value: 'salary' },
        //   { text: 'Income Tax', value: 'tax' },
        //   { text: 'Pension', value: 'pension' },
        //   { text: 'Other Deduction', value: 'deduction' },
        //   { text: 'Net Income', value: 'netIncome' },
        //   { text: 'Actions', value: 'actions', sortable: false },
        // ],
        headers: [
          { text: 'Payroll Code',align: 'start',value: 'payrollCode'},
          // { text: 'Department',align: 'start',value: 'department'},
          { text: 'Month', value: 'month' },
          { text: 'Year', value: 'year' },
          // { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        payrolls: [],
        permanentPayrolls:[],
        laborPayrolls:[],
        previousPayroll: [],
        lessWorkingDays: [],  
        payrollHistory: [],
        totalPayrollHistory: [],
        totalLaborPayrollHistory: [],
        lessPayrollHistory:[],
        totalBasciSalaryPayrollHistory: 0.0,
        totalSumPermanentPayroll: [],
        totalSumLaborPayroll: [],
        permanentPayrollHistorySummary: [],
        laborPayrollHistorySummary: [],
        paymentSummary: [],
        totalPayrollHistory: [],
        permanentPayrollHistorys: [],
        laborPayrollHistorys: []
    }
    const getters = {
      getPayrolls(state){
        return state.payrolls
      },
      getPermanentPayrolls(state){
        return state.permanentPayrolls
      },
      getTotalBasciSalaryPayrollHistory(state){
        return state.totalBasciSalaryPayrollHistory
      },
      getLaborPayrolls(state){
        return state.laborPayrolls
      },
      getSumExpectedTotalBasicSalary(state){
        return sumArrayObject(state.payrolls, 'grossSalary')
      }
    }
    const mutations = {
      SET_PAYROLL(state, result){
        state.payrolls = result
      },
      SET_PERMANENT_PAYROLL(state,result){
        state.permanentPayrolls = result
      },
      SET_LABOR_PAYROLL(state,result){
        state.laborPayrolls = result
      },
      SET_PREVIOUS_PAYROLL(state,result){
        state.previousPayroll = result
      },
      SET_LESS_WORKING_DAYS(state,result){
        state.lessWorkingDays = result
      },
      SET_PAYROLL_HISTORY(state,result){
        state.payrollHistory = result
      },
      SET_TOTAL_PAYROLL_HISTORY(state,result){
        state.totalPayrollHistory = result
      },
      SET_TOTAL_LABOR_PAYROLL_HISTORY(state,result){
        state.totalLaborPayrollHistory = result
      },
      SET_LESS_WORKING_DAYS_PAYROLL_HISTORY(state,result){
        state.lessPayrollHistory = result
      },
      SET_TOTAL_PREVIOUS_MONTH_BASIC_SALARY(state,result){
        // console.log("this is test====>"+result.totalActualWorkInBirr);
        state.totalBasciSalaryPayrollHistory = result
        // state.totalBasciSalaryPayrollHistory = result
      },
      SET_TOTAL_SUM_PAYROLL_HISTORY(state,result){
        state.totalSumPermanentPayroll = result
      },
      SET_TOTAL_SUM_LABOR_PAYROLL_HISTORY(state,result){
        state.totalSumLaborPayroll = result
      },
      SET_PERMANENT_PAYROLL_HISTORY_SUMMARY(state,result){
        state.permanentPayrollHistorySummary = result
      },
      SET_LABOR_PAYROLL_HISTORY_SUMMARY(state,result){
        state.laborPayrollHistorySummary = result
      },
      SET_PAYMENT_SUMMARY(state,result){
        state.paymentSummary = result
      },
      GET_PAYROLL_HISTORY(state,result){
        state.totalPayrollHistory = result
      },
      GET_PERMANENT_PAYROLL_HISTORY(state,result){
        state.permanentPayrollHistorys = result
      },
      GET_LABOR_PAYROLL_HISTORY(state,result){
        state.laborPayrollHistorys = result
      }
    }
    const actions = {
      async addPayroll({commit}, payroll) {
        await PayrollDataService.create(payroll)
        .then(function (res) {
          // console.log(res.data.message);
          if(res.data.message == 'Already Created!'){
            alert("Payroll Already Created!");
          }else{
            alert("Payroll Created!")
          }
          
          // return res.data;
        })
      },
      async getPayrollHistory({commit}, payload) {
        await PayrollDataService.getPayrollHistory(payload)
        .then(function (res) {
          console.log(res.data);
          commit('GET_PAYROLL_HISTORY',res.data)
        })
      },
      async getPermanentPayrollHistory({commit}, payload) {
        await PayrollDataService.getPermanentPayrollHistory(payload)
        .then(function (res) {
          console.log(res.data);
          commit('GET_PERMANENT_PAYROLL_HISTORY',res.data)
        })
      },
      async getLaborPayrollHistory({commit}, payload) {
        await PayrollDataService.getLaborPayrollHistory(payload)
        .then(function (res) {
          console.log(res.data);
          commit('GET_LABOR_PAYROLL_HISTORY',res.data)
        })
      },
      async fetchPermanentPayrollHistorySummary({commit}) {
        await PayrollDataService.getAllPermanentPayrollHistory()
        .then(function (res) {
          commit('SET_PERMANENT_PAYROLL_HISTORY_SUMMARY',res.data);
        })
      },
      async fetchLaborPayrollHistorySummary({commit}) {
        await PayrollDataService.getAllLaborPayrollHistory()
        .then(function (res) {
          commit('SET_LABOR_PAYROLL_HISTORY_SUMMARY',res.data);
        })
      },
      async fetchFetchPayroll({commit},payload) {
        await PayrollDataService.getAll(payload)
        .then(function (res) {
          commit('SET_PAYROLL',res.data);
        })
      },
      async fetchPermanentPayroll({commit}) {
        await PayrollDataService.getAllPermanent()
        .then(function (res) {
          commit('SET_PERMANENT_PAYROLL',res.data);
        })
      },
      async fetchPaymentSummary({commit},payload) {
        await PayrollDataService.getPaymentSummary(payload)
        .then(function (res) {
          console.log("===================");
          console.log(res.data);
          console.log("=====================");
          commit('SET_PAYMENT_SUMMARY',res.data);
        })
      },
      async fetchLaborPayroll({commit}) {
        await PayrollDataService.getAllLabor()
        .then(function (res) {
          commit('SET_LABOR_PAYROLL',res.data);
        })
      },
      async fetchTotalPermanentPayrollHistory({commit},payload) {
        await PayrollDataService.getTotalPermanentPayrollHistory(payload)
        .then(function (res) {
          commit('SET_TOTAL_PAYROLL_HISTORY',res.data);
        })
      },
      async fetchTotalSumPermanentPayrollHistory({commit}) {
        await PayrollDataService.getTotalSumPermanentPayrollHistory()
        .then(function (res) {
          commit('SET_TOTAL_SUM_PAYROLL_HISTORY',res.data);
        })
      },
      async fetchTotalSumLaborPayrollHistory({commit}) {
        await PayrollDataService.getTotalSumLaborPayrollHistory()
        .then(function (res) {
          commit('SET_TOTAL_SUM_LABOR_PAYROLL_HISTORY',res.data);
        })
      },
      async fetchTotalLaborPayrollHistory({commit},payload) {
        await PayrollDataService.getTotalLaborPayrollHistory(payload)
        .then(function (res) {
          commit('SET_TOTAL_LABOR_PAYROLL_HISTORY',res.data);
        })
      },
      async fetchPreviousMonthPayroll({commit},payload) {
        await PayrollDataService.getAllPreviousPayroll(payload)
        .then(function (res) {
          commit('SET_PREVIOUS_PAYROLL',res.data);
        })
      },
      async fetchPreviousMonthTotalBasicSalary({commit},payload) {
        await PayrollDataService.getPreviousMonthTotalBasicSalary(payload)
        .then(function (res) {
          console.log("Loggggggggggggggggggggg");
          // let obj = res.data.map(item => ({}))
          console.log(res.data[0]);
          commit('SET_TOTAL_PREVIOUS_MONTH_BASIC_SALARY',res.data);
        })
      },
      async fetchLessWorkingDaysInPayrollHistory({commit},payload) {
        await PayrollDataService.getLessWorkingDaysPayrollHistory(payload)
        .then(function (res) {
          commit('SET_LESS_WORKING_DAYS_PAYROLL_HISTORY',res.data);
        })
      },
      async fetchPayrollHistory({commit}) {
        await PayrollDataService.getPayrollHistory()
        .then(function (res) {
          commit('SET_PAYROLL_HISTORY',res.data);
        })
      },
      async updatePayroll({commit},{id,payrollData}) {
        await PayrollDataService.update(id,payrollData)
        .then(function (res) {
          console.log(res);
        })
        
      },
      async backupPayroll({commit}) {
        await PayrollDataService.createBackup()
        .then(function (res) {
          console.log(res.data);
          // return res.data;
        })
      },
      async deletePayroll({commit}) {
        await PayrollDataService.deleteAllPayroll()
        .then(function (res) {
          console.log(res.data);
          // return res.data;
        })
      },
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}